import React, { useEffect, useRef, useState } from "react";
import "./Home.page.scss";
import {
  Footer,
  GoTop,
  Header,
  Menu,
  ProductCategoryList,
  SearchBar,
} from "../../components";
import { ProductCategoryModelType } from "../../types";
import {
  productCategoryIndexAction,
  productIndexAction,
  setSelectedProductCategory,
  useAppDispatch,
  useAppSelector,
} from "../../store";

export const HomePage = () => {
  const ref = useRef(null);
  const [filledProductCategories, setFilledProductCategories] = useState<
    ProductCategoryModelType[]
  >([]);
  const [criteria, setCriteria] = useState("");

  const dispatch = useAppDispatch();
  const {
    productCategories,
    isIndexFetching: isIndexFetchingProductCategories,
    selectedProductCategory,
  } = useAppSelector((store) => store.productCategory);
  const { products, isIndexFetching: isIndexFetchingProducts } = useAppSelector(
    (store) => store.product
  );

  useEffect(() => {
    dispatch(productCategoryIndexAction());
    dispatch(productIndexAction());
  }, [dispatch]);

  useEffect(() => {
    setFilledProductCategories([...productCategories]);
  }, [productCategories]);

  useEffect(() => {
    if (!isIndexFetchingProductCategories && !isIndexFetchingProducts) {
      let updatedProductCategories = [...productCategories];

      const normalizedCriteria = criteria
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase();
      const criteriaRegExp = new RegExp(normalizedCriteria, "u");

      const filteredProductList = products.filter(
        (p) =>
          p.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .match(criteriaRegExp) ||
          p.description
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            ?.match(criteriaRegExp)
      );

      for (let product of filteredProductList) {
        let productCategoriesIndex = productCategories.findIndex(
          (pc) => product.productCategory.data.id === pc.id
        );
        if (productCategoriesIndex !== -1) {
          updatedProductCategories[productCategoriesIndex] = {
            ...updatedProductCategories[productCategoriesIndex],
            products: [
              ...updatedProductCategories[productCategoriesIndex].products,
              product,
            ],
          };
        } else {
          for (let updatedProductCategoryIndex in updatedProductCategories) {
            const updatedProductCategory =
              updatedProductCategories[updatedProductCategoryIndex];
            const productSubCategoryIndex =
              updatedProductCategory.subcategories?.findIndex(
                (s) => s.id === product.productCategory.data.id
              );
            if (
              productSubCategoryIndex !== -1 &&
              typeof productSubCategoryIndex !== "undefined" &&
              updatedProductCategory.subcategories &&
              typeof updatedProductCategory.subcategories[
                productSubCategoryIndex
              ] !== "undefined"
            ) {
              const updatedSubcategory: Omit<
                ProductCategoryModelType,
                "subcategories"
              > = {
                ...updatedProductCategory.subcategories[
                  productSubCategoryIndex
                ],
                products: [
                  ...updatedProductCategory.subcategories[
                    productSubCategoryIndex
                  ].products,
                  product,
                ],
              };
              updatedProductCategories[updatedProductCategoryIndex] = {
                ...updatedProductCategories[updatedProductCategoryIndex],
                subcategories: [updatedSubcategory],
              };
            }
          }
        }
      }
      setFilledProductCategories(updatedProductCategories);
    }
  }, [
    isIndexFetchingProductCategories,
    isIndexFetchingProducts,
    productCategories,
    products,
    criteria,
  ]);

  useEffect(() => {
    if (selectedProductCategory) {
      const element = document.getElementById(
        `product-category-${selectedProductCategory.id}`
      );
      if (element) {
        const headerOffset = 300;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selectedProductCategory]);

  const onSelectProductCategoryHandler = (pc: ProductCategoryModelType) => {
    dispatch(setSelectedProductCategory(pc));
  };

  const onSearchHandler = (q: string) => {
    setCriteria(q.trim());
  };

  const onClearHandler = () => {
    setCriteria("");
  };

  return (
    <div className="HomePage">
      <main ref={ref}>
        <Header />
        <SearchBar onClear={onClearHandler} onSearch={onSearchHandler} />
        <Menu
          onSelectProductCategory={onSelectProductCategoryHandler}
          productCategories={productCategories}
        />
        {/* <div className="SinTaccBanner">
          <SinTaccIcon />
          <span>Consultanos por los productos Sin T.A.C.C.</span>
        </div> */}
        <ProductCategoryList productCategories={filledProductCategories} />
        <GoTop />
      </main>
      <Footer />
    </div>
  );
};
